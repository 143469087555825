import { template as template_4b251e99da9f40d2b5af4c6357ed0ecc } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const OpLikesCell = template_4b251e99da9f40d2b5af4c6357ed0ecc(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="op_likes"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="likes"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default OpLikesCell;
