import { template as template_8d8c35e0f44b4c93bcb989e12a7c5164 } from "@ember/template-compiler";
const FKControlMenuContainer = template_8d8c35e0f44b4c93bcb989e12a7c5164(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
