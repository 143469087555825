import { template as template_0af620cdf8044d9abc6864ff65e0a918 } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const LikesCell = template_0af620cdf8044d9abc6864ff65e0a918(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="likes"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="likes"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default LikesCell;
