import { template as template_ea570c7d452d451385679adf0942fa25 } from "@ember/template-compiler";
const FKLabel = template_ea570c7d452d451385679adf0942fa25(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
